import { Modal } from "bootstrap";
export interface Error {
  message: string;
}

export const initialCustomJsFunctions = () => {
  Object.defineProperty(String.prototype, "firstSmall", {
    value: function () {
      return this.charAt(0).toLowerCase() + this.slice(1);
    },
    enumerable: false,
  });
};

export const addDebugDate = (name = "", data): boolean => {
  const debugMode = process.env.VUE_APP_DEBUG_MODE;
  if (debugMode) {
    console.log(name, data);
  }
  return true;
};

export const moreErrorHtmlByPopup = (errorText = ""): string => {
  Modal.getInstance();
  errorText = errorText == undefined || errorText == "" ? "----" : errorText;
  const html = `
        <div class="flex-row w-100">
          <div
          class="collapsible rotate collapsed w-100"
          data-bs-toggle="collapse"
          href="#show_more_info_error"
          role="button"
          aria-expanded="false"
          aria-controls="show_more_info_error"
        >
          <div class="text-center w-100">
            <div class="fw-bold text-hover-primary w-100">
              Show more info
            </div>
          </div>
        </div>
        <div
          id="show_more_info_error"
          class="collapse w-100 text-gray-800"
          data-bs-parent="#show_more_info"
          style=""
        >
          <div class="py-3 px-4 w-100">Error messages from API: ${errorText}</div>
        </div>
      </div>
          
    `;
  return html;
};

export default {
  Error,
  initialCustomJsFunctions,
  addDebugDate,
  moreErrorHtmlByPopup,
};
