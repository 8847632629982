import ApiService from "@/core/services/ApiService";
import { IUser } from "@/core/data/users";

interface UserDetailsResponse {
  result: IUser | null;
}

interface DefaultResponse {
  result: boolean;
}

class UserApiService {
  /**
   * @param userId: number
   */
  public static getUserDetails(userId): Promise<UserDetailsResponse> {
    return new Promise((resolve, reject) => {
      ApiService.get(`User/${userId}`)
        .then(({ data }) => {
          const responseData = {
            result: null,
          };
          if (data.success == true && data.data[0] !== undefined) {
            responseData.result = data.data[0];
          }
          return resolve(responseData);
        })
        .catch((response) => {
          return reject(response);
        });
    });
  }

  public static changeLangCode(params): Promise<DefaultResponse> {
    return new Promise((resolve, reject) => {
      ApiService.post(`User/changeLangCode`, params)
        .then(({ data }) => {
          const responseData = {
            result: false,
          };
          if (data.success == true) {
            responseData.result = true;
          }
          return resolve(responseData);
        })
        .catch((response) => {
          return reject(response);
        });
    });
  }

  public static changeThemeMode(params): Promise<DefaultResponse> {
    return new Promise((resolve, reject) => {
      ApiService.post(`User/changeThemeMode`, params)
        .then(({ data }) => {
          const responseData = {
            result: false,
          };
          if (data.success == true) {
            responseData.result = true;
          }
          return resolve(responseData);
        })
        .catch((response) => {
          return reject(response);
        });
    });
  }

  public static changePassword(params): Promise<DefaultResponse> {
    return new Promise((resolve, reject) => {
      ApiService.post(`User/changePassword`, params)
        .then(({ data }) => {
          const responseData = {
            result: false,
          };
          if (data.success == true) {
            responseData.result = true;
          }
          return resolve(responseData);
        })
        .catch((response) => {
          return reject(response);
        });
    });
  }
}

export default UserApiService;
