import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse } from "axios";
import { addDebugDate } from "@/core/services/CustomFunctions";
import Swal from "sweetalert2/dist/sweetalert2.js";
import i18n from "@/core/plugins/i18n";

class AuthError extends Error {
  constructor(message) {
    super(message); // (1)
    this.name = "AuthError"; // (2)
  }
}

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(token = "" as string): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token !== "" ? token : JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  public static checkAuth(): boolean {
    if (JwtService.validToken() == false) {
      ApiService.logOutMessage();
      throw new AuthError("Failed authorization");
    }
    return true;
  }

  /**
   * @description logoutMessages when respons code 401
   */
  public static logOutMessage(): void {
    const { t } = i18n.global;
    Swal.fire({
      text: t("Failed authorization"),
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: t("Ok, logout!"),
      customClass: {
        confirmButton: "btn btn-primary",
      },
    }).then(() => {
      JwtService.destroyToken();
      window.location.href = "/sign-in";
    });
  }
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: object
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: object
  ): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      const check = ApiService.checkAuth();
      if (check == true) {
        ApiService.vueInstance.axios
          .get(resource, params)
          .then((data) => {
            addDebugDate(`responseCustomQueryGetSUCCESS - ${resource}`, data);
            return resolve(data);
          })
          .catch(({ response }) => {
            addDebugDate(`responseCustomQueryGetFAIL - ${resource}`, response);
            let message = `Fatal error. Code: ${response.status}`;
            if (response !== undefined && response.data.success == false) {
              message = response.data.errorMessage;
            } else if (response.status == 404) {
              message = `Url - 404 Not Found`;
            }
            const error = new Error(message);
            return reject(error);
          });
      }
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      const check = ApiService.checkAuth();
      if (check == true) {
        ApiService.vueInstance.axios
          .get(`${resource}/${slug}`)
          .then((data) => {
            addDebugDate(`responseGetSUCCESS - ${resource}`, data);
            return resolve(data);
          })
          .catch(({ response }) => {
            addDebugDate(`responseGetFAIL - ${resource}`, response);
            let message = `Fatal error. Code: ${response.status}`;
            if (response !== undefined && response.data.success == false) {
              message = response.data.errorMessage;
            } else if (response.status == 404) {
              message = `Url - 404 Not Found`;
            }
            const error = new Error(message);
            return reject(error);
          });
      }
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: object
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params: object): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      const check = ApiService.checkAuth();
      if (check == true) {
        ApiService.vueInstance.axios
          .post(`${resource}`, params)
          .then((data) => {
            addDebugDate(`responsePostSUCCESS - ${resource}`, data);
            return resolve(data);
          })
          .catch(({ response }) => {
            addDebugDate(`responsePostFAIL - ${resource}`, response);
            let message = `Fatal error. Code: ${response.status}`;
            if (response !== undefined && response.data.success == false) {
              message = response.data.errorMessage;
            } else if (response.status == 404) {
              message = `Url - 404 Not Found`;
            }
            const error = new Error(message);
            return reject(error);
          });
      }
    });
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: object
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: object
  ): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      const check = ApiService.checkAuth();
      if (check == true) {
        ApiService.vueInstance.axios
          .put(`${resource}/${slug}`, params)
          .then((data) => {
            addDebugDate(`responseUpdateSUCCESS - ${resource}`, data);
            return resolve(data);
          })
          .catch(({ response }) => {
            addDebugDate(`responseUpdateFAIL - ${resource}`, response);
            let message = `Fatal error. Code: ${response.status}`;
            if (response !== undefined && response.data.success == false) {
              message = response.data.errorMessage;
            } else if (response.status == 404) {
              message = `Url - 404 Not Found`;
            }
            const error = new Error(message);
            return reject(error);
          });
      }
    });
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: object
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params: object): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      const check = ApiService.checkAuth();
      if (check == true) {
        ApiService.vueInstance.axios
          .put(`${resource}`, params)
          .then((data) => {
            addDebugDate(`responsePutSUCCESS - ${resource}`, data);
            return resolve(data);
          })
          .catch(({ response }) => {
            addDebugDate(`responsePutFAIL - ${resource}`, response);
            let message = `Fatal error. Code: ${response.status}`;
            if (response !== undefined && response.data.success == false) {
              message = response.data.errorMessage;
            } else if (response.status == 404) {
              message = `Url - 404 Not Found`;
            }
            const error = new Error(message);
            return reject(error);
          });
      }
    });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      const check = ApiService.checkAuth();
      if (check == true) {
        ApiService.vueInstance.axios
          .delete(resource)
          .then((data) => {
            addDebugDate(`responseDeleteSUCCESS - ${resource}`, data);
            return resolve(data);
          })
          .catch(({ response }) => {
            addDebugDate(`responseDeleteFAIL - ${resource}`, response);
            let message = `Fatal error. Code: ${response.status}`;
            if (response !== undefined && response.data.success == false) {
              message = response.data.errorMessage;
            } else if (response.status == 404) {
              message = `Url - 404 Not Found`;
            }
            const error = new Error(message);
            return reject(error);
          });
      }
    });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static patch(resource: string): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      const check = ApiService.checkAuth();
      if (check == true) {
        ApiService.vueInstance.axios
          .patch(resource)
          .then((data) => {
            addDebugDate(`responsePatchSUCCESS - ${resource}`, data);
            return resolve(data);
          })
          .catch(({ response }) => {
            addDebugDate(`responsePatchFAIL - ${resource}`, response);
            let message = `Fatal error. Code: ${response.status}`;
            if (response !== undefined && response.data.success == false) {
              message = response.data.errorMessage;
            } else if (response.status == 404) {
              message = `Url - 404 Not Found`;
            }
            const error = new Error(message);
            return reject(error);
          });
      }
    });
  }
}

export default ApiService;
