import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboard"],
        },
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
        meta: {
          pageTitle: "Account",
          breadcrumbs: ["Account"],
        },
      },
      {
        path: "/orders/list",
        name: "orders",
        component: () => import("@/views/orders/OrdersListing.vue"),
        meta: {
          pageTitle: "Orders",
          breadcrumbs: ["Orders"],
        },
      },
      {
        path: "/deliveries/list",
        name: "deliveries",
        component: () => import("@/views/deliveries/DeliveriesListing.vue"),
        meta: {
          pageTitle: "Deliveries",
          breadcrumbs: ["Deliveries"],
        },
      },
      {
        path: "/invoices/list",
        name: "invoices",
        component: () => import("@/views/invoices/InvoicesListing.vue"),
        meta: {
          pageTitle: "Invoices",
          breadcrumbs: ["Invoices"],
        },
      },
      {
        path: "/correction-invoices/list",
        name: "correction-invoices",
        component: () =>
          import("@/views/correctionInvoices/CorrectionInvoicesListing.vue"),
        meta: {
          pageTitle: "Correction Invoices",
          breadcrumbs: ["Invoices"],
        },
      },
      {
        path: "/customers/list",
        name: "customers-list",
        component: () => import("@/views/customers/CustomersListing.vue"),
        meta: {
          pageTitle: "Customers list",
          breadcrumbs: ["Customers"],
        },
      },
      {
        path: "/customers/details/:cardCode",
        name: "customer-details",
        component: () => import("@/views/customers/CustomerDetails.vue"),
        meta: {
          pageTitle: "Customer details",
          breadcrumbs: ["Customers"],
        },
      },
      {
        path: "/doc/:type/details/:docNum",
        name: "doc-details",
        component: () => import("@/views/document/DocDetails.vue"),
        meta: {
          pageTitle: "Document details",
          breadcrumbs: ["Document"],
        },
      },
      {
        path: "/admin/user/listing",
        name: "admin-user-listing",
        component: () => import("@/views/admin/user/UsersListing.vue"),
        meta: {
          middleware: "admin",
          pageTitle: "Users list",
          breadcrumbs: ["Admin", "User"],
        },
      },
      {
        path: "/admin/user/details/:userId",
        name: "admin-user-details",
        component: () => import("@/views/admin/user/UserDetails.vue"),
        meta: {
          middleware: "admin",
          pageTitle: "User details",
          breadcrumbs: ["Admin", "User"],
        },
      },
      {
        path: "/tickets/list",
        name: "tickets-list",
        component: () => import("@/views/tickets/TicketsListing.vue"),
        meta: {
          betaVersion: true,
          pageTitle: "Tickets list",
          breadcrumbs: ["Tickets"],
        },
      },
      {
        path: "/tickets/details/:ticketId",
        name: "ticket-details",
        component: () => import("@/views/tickets/TicketDetails.vue"),
        meta: {
          betaVersion: true,
          pageTitle: "Ticket details",
          breadcrumbs: ["Tickets"],
        },
      },
      {
        path: "/goods-release/list",
        name: "goods-release",
        component: () => import("@/views/goodsRelease/GoodsReleaseListing.vue"),
        meta: {
          betaVersion: true,
          pageTitle: "Goods release",
          breadcrumbs: ["Goods release"],
        },
      },
      {
        path: "/goods-release/details/:goodsIssueId",
        name: "goods-release-detail",
        component: () => import("@/views/goodsRelease/GoodsReleaseDetails.vue"),
        meta: {
          betaVersion: true,
          pageTitle: "Goods release details",
          breadcrumbs: ["Goods release details"],
        },
      },
      {
        path: "/returns/list",
        name: "returns-list",
        component: () => import("@/views/returns/ReturnsListing.vue"),
        meta: {
          betaVersion: true,
          pageTitle: "Returns list",
          breadcrumbs: ["Returns"],
        },
      },
      {
        path: "/returns/:returnId",
        name: "return-details",
        component: () => import("@/views/returns/ReturnsDetails.vue"),
        meta: {
          betaVersion: true,
          pageTitle: "Return details",
          breadcrumbs: ["Returns"],
        },
      },
      {
        path: "/service-request/list",
        name: "service-request-list",
        component: () =>
          import("@/views/serviceRequest/ServiceRequestsListing.vue"),
        meta: {
          pageTitle: "Service request list",
          breadcrumbs: ["Service requests"],
        },
      },
      {
        path: "/service-request/details/:callId",
        name: "service-request-detail",
        component: () =>
          import("@/views/serviceRequest/ServiceRequestDetails.vue"),
        meta: {
          pageTitle: "Service request details",
          breadcrumbs: ["Service request details"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  await store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  await store.dispatch(Actions.VERIFY_AUTH);

  // before page access check if page requires authentication
  const nextName = checkAuth(to);
  if (nextName !== "") {
    next(nextName);
  }
  next();
  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

const checkAuth = (to) => {
  let auth = "";
  const user = store.getters.currentUser;

  switch (to.meta.middleware) {
    case "admin":
      auth =
        store.getters.isUserAuthenticated == false
          ? "sign-in"
          : user.isAdmin == false || checkBetaVersion(to)
          ? "dashboard"
          : "";
      break;
    case "auth":
      auth =
        store.getters.isUserAuthenticated == false
          ? "sign-in"
          : checkBetaVersion(to)
          ? "dashboard"
          : "";
      break;
    default:
      auth = "";
      break;
  }

  return auth;
};

const checkBetaVersion = (to): boolean => {
  return to.meta.betaVersion !== undefined &&
    to.meta.betaVersion == true &&
    process.env.VUE_APP_SHOW_BETA == 0
    ? true
    : false;
};

export default router;
