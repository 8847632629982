import axios from "axios";
import { AxiosResponse } from "axios";
import { addDebugDate } from "@/core/services/CustomFunctions";

/**
 * @description service to call HTTP request via Axios
 */
class AuthService {
  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: object
   * @returns Promise<AxiosResponse>
   */
  public static post(params: object, token = ""): Promise<AxiosResponse> {
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.baseURL = process.env.VUE_APP_API_AUTH_URL;
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`Auth`, params)
        .then((data) => {
          addDebugDate(`responsePostSUCCESS - Auth`, data);
          return resolve(data);
        })
        .catch(({ response }) => {
          addDebugDate(`responsePostFAIL - Auth`, response);
          let message = `Fatal error. Code: ${response.status}`;
          if (response !== undefined && response.data.success == false) {
            message = response.data.errorMessage;
          } else if (response.status == 404) {
            message = `Url - 404 Not Found`;
          }
          const error = new Error(message);
          return reject(error);
        });
    });
  }
}

export default AuthService;
