import ApiService from "@/core/services/ApiService";
import UserApiService from "@/core/services/UserApiService";
import JwtService from "@/core/services/JwtService";
import { addDebugDate } from "@/core/services/CustomFunctions";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { IUser } from "@/core/data/users";
import AuthService from "@/core/services/AuthService";
export interface UserAuthInfo {
  errors: unknown;
  user: IUser;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as IUser;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): IUser {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
  @Mutation
  [Mutations.SET_AUTH_TOKEN](token) {
    JwtService.saveToken(token);
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = {};
    addDebugDate("setUser", this.user);
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as IUser;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.SET_USER](token) {
    const tokenData = JwtService.parseTokenData(token);
    ApiService.setHeader(token);
    return UserApiService.getUserDetails(tokenData.user_id)
      .then(({ result }) => {
        if (result !== null) {
          const user = result;
          if (user.enabled == false) {
            this.context.commit(Mutations.SET_ERROR, ["Account disabled"]);
            return false;
          }
          this.context.commit(Mutations.SET_AUTH, { token, user });
          this.context.dispatch(Actions.SET_LANG_ACTION, user.langCode);
          this.context.commit(Mutations.SET_LAYOUT_CONFIG_PROPERTY, {
            property: "general.mode",
            value: user.themeMode,
          });
          this.context.dispatch(Actions.SET_THEME_MODE_ACTION, user.themeMode);

          return true;
        }
      })
      .catch((response) => {
        const error = {
          authUser: response,
        };
        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.SET_ERROR, error);
        return false;
      });
  }

  @Action
  [Actions.LOGIN](credentials) {
    return AuthService.post(credentials)
      .then(({ data }) => {
        if (data.success == true) {
          const token = data.data[0].token;
          this.context.commit(Mutations.SET_AUTH_TOKEN, token);
          return this.context.dispatch(Actions.SET_USER, token);
        }
      })
      .catch((response) => {
        const error = {
          authUser: response,
        };
        this.context.commit(Mutations.SET_ERROR, error);
        return null;
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    const token = JwtService.getToken();
    if (token) {
      const tokenData = JwtService.parseTokenData(token);
      const time = Math.floor(Date.now() / 1000);
      if (tokenData.exp <= time) {
        this.context.commit(Mutations.PURGE_AUTH);
        return true;
      }
      if (!this.currentUser.userId) {
        await this.context.dispatch(Actions.SET_USER, token);
        return true;
      }
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.CHANGE_PASSWORD](data) {
    ApiService.setHeader();
    return UserApiService.changePassword(data)
      .then(({ result }) => {
        return result;
      })
      .catch((response) => {
        const error = {
          changePassword: response,
        };
        this.context.commit(Mutations.SET_ERROR, error);
        return false;
      });
  }
}
